import {
  theme,
  List,
  Space,
  Input,
  Flex,
  Image,
  Upload,
  Spin,
  message,
  Layout,
  Button,
} from "antd";
import {
  SendOutlined,
  LinkOutlined,
  LoadingOutlined,
  SmileOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import withThemeSwitch from "../Component/withThemeSwitch";
import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useGlobalContext } from "../Component/GlobalContext";
import { gql, useMutation, useSubscription, useQuery } from "@apollo/client";
import { uploadData, getUrl, remove } from "aws-amplify/storage";
import styled, { keyframes } from "styled-components";
import MainHeader from "../Component/Header";

const INSERT_CHATROOM = gql`
  mutation MyMutation($input_user_id: uuid!) {
    insert_support_chatroom_one(object: { id: $input_user_id }) {
      id
      name
    }
  }
`;

const GET_CHATROOM = gql`
  query MyQuery($input_user_id: uuid!) {
    support_chatroom(where: { id: { _eq: $input_user_id } }) {
      id
      supporter_unread_message
      user_unread_message
    }
  }
`;

const UPDATE_CHATROOM = gql`
  mutation MyMutation($input_chatroom_id: uuid!) {
    update_support_chatroom_by_pk(
      pk_columns: { id: $input_chatroom_id }
      _set: { user_unread_message: 0 }
    ) {
      id
    }
  }
`;
const UPDATE_UNREAD_TECHNICIAN_MESSAGE = gql`
  mutation MyMutation($input_chatroom_id: uuid!, $unread_message_count: Int!) {
    update_support_chatroom_by_pk(
      pk_columns: { id: $input_chatroom_id }
      _set: { supporter_unread_message: $unread_message_count }
    ) {
      id
    }
  }
`;
const SUBSCRIBE_CHATROOM = gql`
  subscription SubscribeRequestChatroom($chatroomId: uuid!) {
    support_chatroom(where: { id: { _eq: $chatroomId } }) {
      id
      supporter_unread_message
      user_unread_message
    }
  }
`;
const SUBSCRIBE_MESSAGES = gql`
  subscription SubscribeRequestMessages($chatroomId: uuid!) {
    support_message(
      where: { chatroom_id: { _eq: $chatroomId } }
      order_by: { date: asc }
    ) {
      id
      sender_id
      content
      date
      sender_name
      attachment_path
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage(
    $chatroomId: uuid!
    $senderId: uuid!
    $content: String!
    $senderName: String!
    $attachmentPath: String
  ) {
    insert_support_message(
      objects: {
        chatroom_id: $chatroomId
        sender_id: $senderId
        content: $content
        sender_name: $senderName
        attachment_path: $attachmentPath
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const formatFileName = (file) => {
  const fileNameParts = file.name.split(".");
  const extension = fileNameParts.pop();
  const baseName = fileNameParts.join(".");
  const timestamp = Date.now();
  const newFileName = `${baseName}-${timestamp}.${extension}`;
  return newFileName;
};

const isImage = (path) => {
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];
  return imageExtensions.some((ext) => path.toLowerCase().endsWith(ext));
};

const RequestSupport = () => {
  const [inputMessage, setInputMessage] = useState("");
  const [height, setHeight] = useState(window.innerHeight);
  const { userId, userName, setTempMessageFile } = useContext(useGlobalContext);
  const [chatroom, setChatroom] = useState({ id: userId });
  const [messageList, setMessageList] = useState([]);
  const [highlightMessageAmount, setHighlightMessageAmount] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const urlCache = React.useRef({});
  const listContainerRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const limiter = useRef(0);

  const {
    token: { colorBgContainer, colorBgLayout },
  } = theme.useToken();

  const [insertChatroom] = useMutation(INSERT_CHATROOM);
  const [updateChatroom] = useMutation(UPDATE_CHATROOM);
  const [updateUnreadTechnicianMessage] = useMutation(
    UPDATE_UNREAD_TECHNICIAN_MESSAGE
  );
  const { refetch } = useQuery(GET_CHATROOM, {
    variables: { input_user_id: chatroom.id },
    onCompleted: (data) => {
      console.log("data", data);
      if (data.support_chatroom.length === 0 && limiter.current === 0) {
        setLoading(true);
        insertChatroom({
          variables: { input_user_id: chatroom.id, name: userName },
        }).then(() => {
          setLoading(false);
          refetch();
        });
        limiter.current = 1;
      } else {
        setChatroom(data.support_chatroom[0]);
      }
    },
  });
  const {
    data: messageData,
    loading: messageLoading,
    error,
  } = useSubscription(SUBSCRIBE_MESSAGES, {
    variables: { chatroomId: chatroom.id },
  });
  const { data: subscriptionData } = useSubscription(SUBSCRIBE_CHATROOM, {
    variables: { chatroomId: chatroom.id },
  });
  const [sendMessage] = useMutation(SEND_MESSAGE);

  if (error) {
    console.log(error.message);
  }

  const scrollToBottom = () => {
    if (listContainerRef.current) {
      listContainerRef.current.scrollTop =
        listContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (subscriptionData) {
      const updatedChatroom = subscriptionData.support_chatroom[0];
      console.log("updatedChatroom", updatedChatroom);
      setChatroom({
        id: updatedChatroom.id,
        supporter_unread_message: updatedChatroom.supporter_unread_message,
        status: updatedChatroom.user_unread_message,
      });
    }
  }, [subscriptionData]);

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  // Get the image urls for the messages
  useEffect(() => {
    if (messageData) {
      const updateMessagesWithUrls = async () => {
        const updatedMessages = await Promise.all(
          messageData.support_message.map(async (message) => {
            if (
              message.attachment_path &&
              !urlCache.current[message.attachment_path]
            ) {
              const url = await getUrl({
                path: message.attachment_path,
                options: {
                  validateObjectExistence: true,
                },
              });
              urlCache.current[message.attachment_path] = url;

              return { ...message, attachment_path: url };
            }
            return {
              ...message,
              attachment_path:
                urlCache.current[message.attachment_path] ||
                message.attachment_path,
            };
          })
        );
        setMessageList(updatedMessages);
      };
      updateMessagesWithUrls();
    }
  }, [messageData]);

  useEffect(() => {
    if (chatroom.status && chatroom.status !== 0) {
      setHighlightMessageAmount(chatroom.status);
      const currentDate = new Date().toISOString();
      updateChatroom({
        variables: { input_chatroom_id: chatroom.id, date: currentDate },
      });
    }
  }, [chatroom, updateChatroom]);

  const attachmentProps = {
    customRequest: async ({
      file,
      onSuccess,
      onError,
      onProgress: onProgressInternal,
    }) => {
      try {
        const formatedFileName = formatFileName(file);
        await uploadData({
          path: `public/support_message/${chatroom.id}/${formatedFileName}`,
          data: file,
          options: {
            onProgress: async (progressEvent) => {
              const percent = Math.round(
                (progressEvent.transferredBytes / progressEvent.totalBytes) *
                  100
              );
              onProgressInternal({ percent }, file);
              if (percent === 100) {
                file.path = `public/support_message/${chatroom.id}/${formatedFileName}`;
              }
            },
          },
        }).result;
        setTempMessageFile(file.path);
        onSuccess(file);
      } catch (error) {
        file.status = "error";
        onError(error, file);
      }
    },

    maxCount: 1,
    beforeUpload: (file) => {
      if (file.size > 1024 * 1024 * 1024) {
        message.error("File size should be less than 1GB");
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      const { status, name } = info.file;
      if (status === "uploading") {
        info.file.name = formatFileName(info.file);
        setUploadFile([info.file]);

        if (status === "error") {
          message.error(`${name} file upload failed.`);
        }
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      style: {
        maxWidth: "20vw",
        fontSize: 12,
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onSuccess: (file) => {
      message.success(`${file.name} file uploaded successfully.`);

      setUploadFile([file]);
    },
    onError: (error, file) => {
      message.error(`${file.name} upload failed. Because ${error}`);
      file.status = "error";
      setUploadFile([file]);
    },
    onRemove: (info) => {
      try {
        console.log("Remove ", uploadFile);
        setTempMessageFile(null);
        remove({
          path: uploadFile[0].path,
        });
      } catch (error) {
        console.log("Error ", error);
      }
      setUploadFile([]);
    },
  };
  const renderItem = useMemo(
    () => (item, index) => {
      const isHighlighted =
        index >= messageList.length - highlightMessageAmount;
      const ListItemComponent = isHighlighted
        ? HighlightedListItem
        : NormalListItem;

      return (
        <ListItemComponent key={item.id}>
          <Space direction="vertical">
            <div>
              <strong>{item.sender_name}</strong> -{" "}
              {new Date(item.date).toLocaleString("en-NZ")}
            </div>
            <div>{item.content}</div>
            {item.attachment_path &&
              (isImage(item.attachment_path.url.pathname) ? (
                <Image
                  width={150}
                  src={item.attachment_path.url.href}
                  onLoad={scrollToBottom}
                />
              ) : (
                <Button
                  icon={<DownloadOutlined />}
                  href={item.attachment_path.url.href}
                  download
                >
                  {item.attachment_path.url.pathname.split("/").pop()}
                </Button>
              ))}
          </Space>
        </ListItemComponent>
      );
    },
    [messageList, highlightMessageAmount]
  );

  return (
    <MainHeader select="Support">
      <Layout.Content
        style={{
          minHeight: 150,
          background: colorBgContainer,
        }}
      >
        <Flex justify="space-between">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <div
                style={{
                  width: "100%",

                  height: height - 208,
                  overflowY: "auto",
                  paddingLeft: 24,
                }}
                ref={listContainerRef}
              >
                <List
                  dataSource={messageList}
                  renderItem={renderItem}
                  loading={messageLoading || loading}
                  locale={{
                    emptyText: (
                      <div style={{ paddingTop: (height - 250) / 3 }}>
                        <SmileOutlined style={{ fontSize: 60 }} />
                        <div style={{ paddingTop: 30 }}>Chat with us</div>
                      </div>
                    ),
                  }}
                  style={{
                    paddingTop:
                      messageLoading || loading ? 0.35 * (height - 32) : 0,
                  }}
                />
              </div>
            </div>

            <div
              hidden={chatroom === null}
              style={{
                backgroundColor: colorBgLayout,
                height: 142,
                alignContent: "center",
                paddingLeft: 24,
              }}
            >
              <Flex direction="horizontal" style={{ width: "100%" }}>
                <Input.TextArea
                  placeholder="Type your message"
                  autoSize={{ minRows: 4, maxRows: 5 }}
                  style={{ width: "95%" }}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                />
                <Space
                  direction="vertical"
                  size={"large"}
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                >
                  <Upload {...attachmentProps} fileList={uploadFile}>
                    <LinkOutlined
                      style={{
                        fontSize: 16,
                        paddingTop: 8,
                      }}
                    />
                  </Upload>
                  <Spin
                    spinning={isSending}
                    indicator={<LoadingOutlined spin />}
                    size="small"
                  />
                  <SendOutlined
                    style={{
                      display: isSending ? "none" : "block",
                      fontSize: 16,
                      position: "fixed",
                      bottom: 32,
                    }}
                    onClick={(e) => {
                      if (inputMessage === "") {
                        message.error("Message can't be empty");
                        return;
                      }

                      updateUnreadTechnicianMessage({
                        variables: {
                          input_chatroom_id: chatroom.id,
                          unread_message_count:
                            chatroom.supporter_unread_message + 1,
                        },
                      });

                      setIsSending(true);
                      sendMessage({
                        variables: {
                          chatroomId: chatroom.id,
                          senderId: userId,
                          content: inputMessage,
                          senderName: userName,
                          attachmentPath:
                            uploadFile.length > 0 ? uploadFile[0].path : null,
                        },
                      }).then(() => {
                        setInputMessage("");
                        setIsSending(false);
                        setTempMessageFile(null);
                        setUploadFile([]);
                      });
                    }}
                  />
                </Space>
              </Flex>
            </div>
          </div>
        </Flex>
      </Layout.Content>
    </MainHeader>
  );
};

export default withThemeSwitch(RequestSupport);

const highlightFade = keyframes`
  25% {
    background-color: #a5f8e2;
  }
  100% {
    background-color: transparent;
  }
`;
const HighlightedListItem = styled(List.Item)`
  border-bottom: none;
  font-size: 16px;
  margin: 10px 0;
  padding: 8px;
  animation: ${highlightFade} 1.2s forwards;
  animation-iteration-count: 2;
`;

const NormalListItem = styled(List.Item)`
  border-bottom: none;
  font-size: 16px;
  margin: 10px 0;
  padding: 8px;
`;
